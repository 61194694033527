<template>
  <footer class="footer">
    <hr class="footer-divider" />
    <div class="container">
      <div class="footer__logo-container">
        <div class="footer__go-up-btn-container">
          <button class="footer__go-up-btn" @click="handleGoUp">
            <svg-icon name="chevron-up" width="24" height="24" />
          </button>
        </div>
        <div v-if="customLogo" class="footer__logo">
          <Logo :custom-logo="customLogo" :is-text="false" />
        </div>
      </div>
      <div class="footer__main">
        <FooterNav
          v-for="(item, index) in menuFooter"
          :key="index"
          :info="item"
          class="footer__nav"
        />
        <div class="footer__social">
          <p class="footer__social-title font font_sm font_bold">
            Поддержка
          </p>
          <Social class="footer__social-list" />
        </div>
      </div>
      <div class="footer__bottom font">
        <p class="footer__copy font_sm">
          &copy; Copyright {{ year }} {{ footerCopy }}
        </p>
      </div>
      <AddedFavCartPopup :display="mqIsMobile" />
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import AddedFavCartPopup from '~/components/elements/AddedFavCartPopup.vue';
// import env from '@/.env.json';
import Social from '@/components/elements/Social';
import FooterNav from '@/layouts/components/Footer/FooterNav';
import Logo from '~/components/elements/Logo';
import './index.css';

export default {
  name: 'Footer',
  components: {
    Logo,
    Social,
    FooterNav,
    AddedFavCartPopup,
  },
  data() {
    return {
      // footerCopy: env.FOOTER_COPY,
      footerCopy: 'CDV. Все права защищены',
      // customLogo: env.FOOTER_LOGO ? `/assets/images/logo/${env.FOOTER_LOGO}` : '',
      customLogo: '/assets/images/logo/logo-no-text.svg',
    }
  },
  computed: {
    ...mapGetters({
      menuFooter: 'getMenuFooter',
    }),
    year() {
      const today = new Date();
      return today.getFullYear();
    },
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet';
    },
    isMainPage() {
      return this.$route.name === 'main';
    },
  },
  methods: {
    handleGoUp() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<script setup>
</script>
