<template>
  <div
    class="fade-box"
    :class="{
      'fade-box-hidden': (!isVisible && !isFadingIn && !isFadingOut),
      'fade-box-invisible fade-box-transition': !isVisible && (isFadingIn || isFadingOut),
      'fade-box-transition': isVisible && !isFadingIn && !isFadingOut
    }"
  >
    <div
      class="fav-variant-container"
    >
      <button class="fav-close-btn" @click="closeQuickProduct">
        <svg-icon name="close-white" width="13" height="13" />
      </button>
      <div class="fav-image">
        <img :src="imgSrc" :alt="name" />
      </div>
      <div class="fav-text">
        <!--        <div class="fav-message">-->
        <!--          {{ message }}-->
        <!--        </div>-->
        <div class="fav-brandName">
          {{ brandName }}
        </div>
        <div class="fav-name">
          {{ name }}
        </div>
        <div class="fav-choose">
          {{ chooseText }}
        </div>
      </div>
      <div class="fav-select-variant">
        <div class="fav-select-variant-list">
          <CustomSelect
            v-model="selectedVariant"
            :options="optionsList"
            label-option-name="title"
            :class="mqIsMobile ? 'dropdown-mobile' : 'dropdown-desktop'"
            @input="handleSelect"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import * as amplitudeTracker from '@amplitude/analytics-browser';
import {phraseDeclension} from '~/plugins/formatting';
import CustomSelect from '@/components/elements/CustomSelect';

export default {
  name: 'AddToFavCartPopupSelect',
  components: {CustomSelect},
  props: {
  },
  data() {
    return {
      name: '',
      brandName: '',
      message: '',
      imgSrc: '',
      chooseText: '',
      productImages: {},
      wordVariantsBonus: ['бонус', 'бонусов', 'бонуса'],
      wordVariantsParameters: ['вариант', 'вариантов', 'варианта'],
      isVisible: false,
      isFadingIn: false,
      isFadingOut: false,
      selectedVariant: {
        value: null,
      },
      productSelected: false,
    }
  },
  computed: {
    ...mapGetters('currency', { getCurrency: 'getCurrency' }),
    ...mapState('quickLook', ['modalState', 'isPending', 'code', 'isHiddenPending']),
    ...mapGetters('cart', ['productInCart', 'getProductCount', 'getProductCountWithBundle']),
    ...mapState('product', ['product', 'getNextCombination']),
    ...mapState('auth', ['hasSession']),
    ...mapGetters('product', [
      'getPrice',
      'getOldPrice',
      'getOptionsCharacteristics',
      'getNextCombination',
      'getCategoryLink',
    ]),
    ...mapGetters('favorites', ['productInFavorites']),
    mqIsMobile() {
      return this.$mq === 'mobileXs';
    },
    isFew() {
      return this.product.stock.qty <= 5 && this.product.stock.qty > 1;
    },
    isLast() {
      return this.product.stock.qty === 1;
    },
    isFavorites() {
      return this.productInFavorites(this.product.productId);
    },
    textFavorite() {
      return this.isFavorites ? 'В избранном' : 'В избранное';
    },
    isMax() {
      return (
        this.getProductCount(this.product.id) >= this.product.stock.qty ||
        this.getProductCountWithBundle(this.product.id) >= this.product.stock.qty
      );
    },
    productGallery() {
      return this.productImages?.media || []
    },
    measurementName() {
      return this.getOptionsCharacteristics.length > 0 ? this.getOptionsCharacteristics[0]?.name : '';
    },
    measurementUnit() {
      return this.getOptionsCharacteristics.length > 0 ?
        (this.getOptionsCharacteristics[0]?.measurement_unit ?? '') :
        '';
    },
    options() {
      return this.getOptionsCharacteristics.length > 0 ? (this.getOptionsCharacteristics[0]?.options ?? []) : [];
    },
    optionsList() {
      return [{
        title: this.chooseText,
        type: null,
        disabled: true,
      }].concat(this.options.filter(opt => opt.value !== null).map(opt => ({
        title: `${opt.name} ${this.measurementUnit}`,
        type: opt.value,
      })))
    },
  },
  watch: {
    product(val) {
      if (this.productSelected) {
        this.addToCartProduct(val);
        this.hideQuickProduct();
        this.productSelected = false;
        this.chooseText = '';
      }
      this.setProductImages();
      this.brandName = val.brand?.name;
      this.name = val.title;
      this.chooseText = `Выберите ${this.measurementName}`;
      this.selectedVariant = {
        title: 'Выберите',
        value: null,
      };
      const img = val.media.find(item => !!item.url)?.url;
      this.imgSrc = img ? (img.small || img.medium || img.large) : '';

      // const scope = this;
      // scope.isFadingIn = true;
      // setTimeout(() => {
      //   scope.isVisible = true;
      //   scope.isFadingIn = false;
      // }, 500);
      // setTimeout(() => {
      //   scope.isVisible = false;
      //   scope.isFadingOut = true;
      // }, 2500);
      // setTimeout(() => {
      //   scope.isFadingOut = false;
      //   scope.name = '';
      //   scope.brandName = '';
      //   scope.message = '';
      //   scope.imgSrc = '';
      // }, 3000);
    },
    measurementName(val) {
      this.chooseText = `Выберите ${val}`;
    },
    modalState(val) {
      const scope = this;
      if (val) {
        scope.isFadingIn = true;
        setTimeout(() => {
          scope.isVisible = true;
          scope.isFadingIn = false;
        }, 500);
      } else {
        scope.isVisible = false;
        scope.isFadingOut = true;
        setTimeout(() => {
          scope.isFadingOut = false;
          scope.name = '';
          scope.brandName = '';
          scope.message = '';
          scope.imgSrc = '';
        }, 500);
      }
    },
    // options(val) {
    //   if (val?.length > 0) {
    //     this.selectedVariant = {
    //       title: `${val[0].name} ${this.measurementUnit}`,
    //       value: val[0].value,
    //     };
    //   }
    // },
  },
  methods: {
    ...mapActions({
      hideQuickProduct: 'quickLook/hideQuickProduct',
      showQuickProduct: 'quickLook/showQuickProduct',
      addToCartProduct: 'cart/addToCartProduct',
    }),
    phraseDeclension,
    setProductImages() {
      const images = {};
      const media = this.product.media;

      if (Array.isArray(media) && media.length > 0) {
        images.media = media.map((image) => {
          return image.id;
        });
      } else {
        images.media = [];
      }

      this.productImages = images;
    },
    handleSelect(val) {
      if (val.type === null) {
        return;
      }
      const codeParam = this.getOptionsCharacteristics?.[0].code;
      const { code, ...rest } = this.getNextCombination(codeParam, val.type);
      this.productSelected = true;
      this.showQuickProduct({ code, category: this.getCategoryLink, hidden: true })

      try {
        const rrApi = {
          addToBasket: (id) => {
            console.log('adding', id)
          },
        };
        // eslint-disable-next-line no-undef
        rrApi.addToBasket(rest.id);
      } finally {
        // await this.addToCartProduct(this.product);
        // this.closeModalHandler();
      }
    },
    closeQuickProduct() {
      this.hideQuickProduct();
      this.chooseText = '';
    },
  },
}
</script>
<style scoped>
.fav-variant-container {
  text-align: center;
  gap: 16px;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0px 4px 18px 0px rgba(25, 25, 25, 0.2);
  z-index: 999999;
  background-color: #ffffff;
  opacity: 1;
  @media all and (max-width: 1279px) {
    position: fixed;
    top: auto;
    bottom: 16px;
    left: 16px;
    width: calc(100vw - 32px);
  }
}
.fav-image {
  width: 200px;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.fav-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  color: #232528;
  width: 360px;
  margin-left: auto;
  margin-right: auto;
  @media all and (max-width: 1279px) {
    width: 100%;
  }
}
.fav-message {
  font-size: 16px;
  font-weight: 300;
}
.fav-brandName {
  font-size: 16px;
  font-weight: 500;
}
.fav-name {
  font-size: 16px;
  font-weight: 300;
}
.fav-choose {
  font-size: 16px;
  font-weight: 500;
  color: #232528;
  margin-bottom: 16px;
}

.fade-box {
  opacity: 1;
  position: absolute;
  right: 0px;
  top: 60px;
}

.fade-box-hidden {
  opacity: 0;
  display: none;
}

.fade-box-invisible {
  opacity: 0;
}

/* ensure box is displayed block during the transition  */
.fade-box-transition.fade-box-hidden {
  display: block;
  opacity: 0;
}

.fade-box-transition {
  transition: opacity 0.5s ease;
  display: block;
}
.fav-close-btn {
  width: 32px;
  height: 32px;
  text-align: center;
  background-color: #232528;
  position: absolute;
  right: 8px;
  top: 8px;
  border-radius: 16px;
  padding-top: 3px;
}
.fav-select-variant-list .custom-select {
  width: 100%;
  font-size: 16px;
  line-height: 24px;
}
.fav-select-variant-list .custom-select .vs__dropdown-toggle {
  height: 42px;
  padding-right: 12px;
  border-radius: 8px;
}
</style>
