<template>
  <transition name="fade">
    <div
      v-scroll-lock="scrollLock"
      class="modal"
      :class="{ 'modal--small': small }"
    >
      <div class="modal__wrap">
        <button class="modal__overlay" type="button" aria-label="Закрыть" @click="closeOverlay">
          <span class="visually-hidden">Закрыть окно</span>
        </button>
        <div class="modal__body" :class="{ 'modal__body--empty': empty, 'modal__body--content-size': contentSize }">
          <header v-if="!empty" class="modal__header">
            <h3 v-if="title" class="modal__title">
              {{ title }}
            </h3>
            <button class="modal__close" type="button" aria-label="Закрыть" @click="close">
              <svg-icon :name="closeIcon" width="15" height="15" />
              <div v-if="closeTitle" class="modal__close-title">{{ closeTitle }}</div>
            </button>
          </header>
          <div class="modal__content" :style="{ position: 'relative' }">
            <slot name="content" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CustomModal',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    empty: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    contentSize: {
      type: Boolean,
      default: false,
    },
    closeIcon: {
      type: String,
      default: 'close-white',
    },
    closeTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      scrollLock: false,
    };
  },
  watch: {
    visible(value) {
      this.scrollLock = value;
    },
  },
  mounted() {
    this.scrollLock = this.visible;
  },
  beforeDestroy() {
    this.scrollLock = false;
  },
  methods: {
    close() {
      this.$emit('close');
    },
    closeOverlay() {
      this.$emit('closeOverlay');
    },
  },
};
</script>
