<template>
  <picture ref="picture">
    <template v-if="media">
      <source
        v-for="(item, index) in imageMedia"
        :key="index"
        :srcset="item.image"
        :media="item.media"
      >
    </template>
    <source v-if="imageSrc.webp" :srcset="imageSrc.webp">
    <img class="lazyLoad" :class="lazyClass" :src="imageSrc.src" :alt="alt" @load="onImgLoad">
  </picture>
</template>

<script>
export default {
  name: 'CustomImagePicture',
  props: {
    webp: {
      type: [Array, String],
      required: true,
    },
    src: {
      type: [Array, String],
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    media: {
      type: [Array, null],
      default: null,
    },
    imageLQIP: {
      type: String,
      default: '/assets/images/lazyimage.png',
    },
  },
  data() {
    return {
      pixelRatio: 0,
      breakpoints: [],
      isViewport: false,
      lazyClass: '',
      observer: null,
    }
  },
  computed: {
    image() {
      return this.getImage(this.src)
    },
    imageWEBP() {
      return this.getImage(this.webp)
    },
    imageMedia() {
      return this.media.map(item => {
        return {
          image: this.getImage(item.image),
          media: item.media,
        }
      })
    },
    imageHeight() {
      return this.media.find(item => item.height)?.height;
    },
    imageSrc() {
      if (!this.isViewport && this.isLazyLoad) {
        return {
          src: this.imageLQIP,
          webp: this.imageWEBP ? this.imageLQIP : '',
        }
      }

      return {
        src: this.image,
        webp: this.imageWEBP,
      }
    },
    isLazyLoad() {
      return this.loading === 'lazy'
    },
  },
  mounted() {
    this.pixelRatio = window.devicePixelRatio

    if (this.isLazyLoad) {
      this.observer = new IntersectionObserver((entries, obs) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log('isIntersecting');
            this.isViewport = true

            obs.unobserve(entry.target);
            this.observer?.disconnect();
            this.observer = null;
          }
        });
      }, {});

      this.observer.observe(this.$refs.picture);
    }
  },
  beforeDestroy() {
    this.breakpoints.length = 0

    if (this.isLazyLoad) {
      this.observer?.disconnect();
      this.observer = null;
    }
  },
  methods: {
    getImage(arr) {
      if (typeof arr === 'string') {
        return arr
      }

      const index = this.getRetinaIndex(arr)

      return arr[index]
    },
    getRetinaIndex(arr) {
      const isRetina = arr.length > 1

      return this.pixelRatio > 1 && isRetina ? 1 : 0
    },
    onImgLoad() {
      if (!this.isViewport && this.isLazyLoad) return

      this.lazyClass = 'isLoaded'
      this.$emit('load')
    },
  },
}
</script>
