<template>
  <CustomModal
    v-show="modalState"
    :visible="modalState"
    :empty="true"
    class="quick-look"
    title=""
    @close="closeModalHandler"
    @closeOverlay="closeModalHandler"
  >
    <template #content>
      <div v-if="!isPending" class="quick-look__wrapper">
        <div v-if="isHiddenPending" class="quick-look__hidden-loader quick-look__wrapper_empty">
          <LoaderDots />
        </div>

        <div class="quick-look__close">
          <Button
            theme="simple"
            :only-icon="true"
            @click="closeModalHandler"
          >
            <SvgIcon name="close" width="14" height="14" />
          </Button>
        </div>

        <QuickLookGallery v-if="!mqIsMobile" :images="productGallery" />

        <div class="quick-look__product">
          <div class="quick-look__header">
            <h1 class="sku-info__name font font_title-l">
              {{ product.title }}
            </h1>
          </div>
          <div class="sku-info__item sku-info__detail">
            <div class="sku-info__rating">
              <template v-if="product.shoppilotEnabled">
                <CustomRate :value="product.rating" disabled />
                <span class="sku-info__reviews font font_xs font_uppercase font_grey">
                    Отзывов {{ product.reviewsCount }}
                  </span>
              </template>
            </div>
            <p class="sku-info__code font font_sm font_grey">
              Артикул {{ product.vendorCode }}
            </p>
          </div>

          <QuickLookGallery v-if="mqIsMobile" :images="productGallery" />

          <div class="quick-look__body">
            <div v-for="characteristic in getOptionsCharacteristics" :key="characteristic.code" class="sku-info__item">
              <div class="sku__parameters">
                <p class="sku__parameters-header font">
                  <span class="sku__parameters-name font_title-m font_bold">
                    {{ characteristic.name }}
                  </span>
                  <span class="sku__parameters-count font font_sm font_grey">
                    {{ phraseDeclension(characteristic.options.length, wordVariantsParameters) }}
                  </span>
                </p>
                <template v-if="characteristic.options.length > 1">
                  <ul v-if="characteristic.type === 'color'" class="sku-options">
                    <li
                      v-for="option in characteristic.options"
                      :key="option.value"
                      class="sku-options__item"
                      itemprop="color"
                    >
                      <button
                        class="sku-options__color"
                        :class="[
                          { ['sku-options__color--selected']: option.isSelected },
                          { ['sku-options__color--dark']: option.value === 'ffffff' },
                        ]"
                        :style="innerStyle(option.value)"
                        :disabled="option.isDisabled"
                        :aria-label="option.name"
                        @click="onSelectOption(characteristic.code, option.value)"
                      />
                    </li>
                  </ul>
                  <ul v-else class="sku-options">
                    <li v-for="option in characteristic.options" :key="option.value" class="sku-options__item">
                      <button
                        class="sku-options__btn font font_sm font_uppercase"
                        :class="{ 'sku-options__btn--selected': option.isSelected }"
                        :disabled="option.isDisabled"
                        @click="onSelectOption(characteristic.code, option.value)"
                      >
                        {{ option.name }} {{ characteristic.measurement_unit }}
                      </button>
                    </li>
                  </ul>
                </template>
              </div>
            </div>
          </div>
          <div class="quick-look__footer">
            <div
              v-if="(product.price && product.price.value) || product.price"
              class="sku-info__item"
            >
              <div class="sku-info__price font">
                <p class="sku__price-wrapper">
                  <span class="sku__price font_title-xl font_bold">{{ getPrice }}</span>
                  <s v-if="product.oldPrice" class="sku__old-price font_title-m font_grey">{{ getOldPrice }}</s>
                </p>
                <p v-if="product.bonus" class="sku-info__bonus font font_sm font_grey">
                  + {{ phraseDeclension(product.bonus, wordVariantsBonus) }}
                </p>
              </div>
              <span v-if="isFew" class="sku__few">Осталось мало</span>
              <span v-else-if="isLast" class="sku__few">Последний</span>
              <div class="quick-look__add-cart">
                <Button v-if="productInCart(product.id)" full outline to="/cart" class="sku-in-cart__button">
                  В корзине
                </Button>
                <Button
                  v-else
                  full
                  :disabled="!product.price || (product.price && !product.price.value) || isMax"
                  class="sku-add-cart__button"
                  @click="onAddCartButtonClick"
                >
                  Купить
                </Button>
                <button
                  class="sku__favorite font font_uppercase font_bold font_sm"
                  :class="{
                    'sku__favorite--active': isFavorites,
                  }"
                  @click="toggleToFavoritesItem"
                >
                  <svg
                    class="sku__favorite-ico"
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.32751 2.48781L9.88192 3.09737L10.4368 2.48825C11.4546 1.37099 12.7047 0.75 14.1583 0.75C16.5761 0.75 19.0178 2.49038 19.0178 5.88153C19.0178 7.45322 18.2898 9.15572 16.7282 10.9984C15.2113 12.7883 12.937 14.6751 9.88303 16.655C6.83005 14.6756 4.55626 12.7889 3.03966 10.999C1.47806 9.15603 0.75 7.45324 0.75 5.88153C0.75 2.49038 3.19174 0.75 5.60953 0.75C7.06116 0.75 8.31009 1.36915 9.32751 2.48781Z"
                      stroke-width="1.5"
                    />
                  </svg>
                  {{ textFavorite }}
                </button>
              </div>
            </div>
            <div v-else class="sku-info__item">
              <span class="sku__end-of-stock">Товар закончился</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="quick-look__wrapper quick-look__wrapper_empty">
        <LoaderDots />
      </div>
    </template>
  </CustomModal>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import CustomModal from '~/components/elements/CustomModal';
import LoaderDots from '~/components/elements/LoaderDots';
import {phraseDeclension} from '~/plugins/formatting';
import CustomRate from '~/components/elements/CustomRate';
import Button from '~/components/elements/Button';
import QuickLookGallery from '~/components/catalog/quickLook/Gallery';

export default {
  name: 'QuickLookModal',
  components: {
    QuickLookGallery,
    Button,
    CustomRate,
    LoaderDots,
    CustomModal,
  },
  data() {
    return {
      productImages: {},
      wordVariantsBonus: ['бонус', 'бонусов', 'бонуса'],
      wordVariantsParameters: ['вариант', 'вариантов', 'варианта'],
    }
  },
  computed: {
    ...mapGetters('currency', { getCurrency: 'getCurrency' }),
    ...mapState('quickLook', ['modalState', 'isPending', 'code', 'isHiddenPending']),
    ...mapGetters('cart', ['productInCart', 'getProductCount', 'getProductCountWithBundle']),
    ...mapState('product', ['product']),
    ...mapState('auth', ['hasSession']),
    ...mapGetters('product', [
      'getPrice',
      'getOldPrice',
      'getOptionsCharacteristics',
      'getNextCombination',
      'getCategoryLink',
    ]),
    ...mapGetters('favorites', ['productInFavorites']),
    mqIsMobile() {
      return this.$mq === 'mobileXs';
    },
    isFew() {
      return this.product.stock.qty <= 5 && this.product.stock.qty > 1;
    },
    isLast() {
      return this.product.stock.qty === 1;
    },
    isFavorites() {
      return this.productInFavorites(this.product.productId);
    },
    textFavorite() {
      return this.isFavorites ? 'В избранном' : 'В избранное';
    },
    isMax() {
      return (
        this.getProductCount(this.product.id) >= this.product.stock.qty ||
        this.getProductCountWithBundle(this.product.id) >= this.product.stock.qty
      );
    },
    productGallery() {
      return this.productImages?.media || []
    },
  },
  watch: {
    product() {
      this.setProductImages()
    },
  },
  methods: {
    phraseDeclension,
    ...mapMutations({
      setIsOpenedAuthModal: 'auth/SET_IS_OPENED_AUTH_MODAL',
    }),
    ...mapActions({
      hideQuickProduct: 'quickLook/hideQuickProduct',
      showQuickProduct: 'quickLook/showQuickProduct',
      addToCartProduct: 'cart/addToCartProduct',
      addFavoritesItem: 'favorites/addFavoritesItem',
      setLastFavoriteAddedItem: 'favorites/setLastFavoriteAddedItem',
      deleteFavoritesItem: 'favorites/deleteFavoritesItem',
    }),
    closeModalHandler() {
      this.hideQuickProduct()
    },
    innerStyle(color) {
      return {
        backgroundColor: `#${color}`,
      };
    },
    onSelectOption(charCode, optValue) {
      const { code } = this.getNextCombination(charCode, optValue);

      this.showQuickProduct({ code, category: this.getCategoryLink, hidden: true })
    },
    async onAddCartButtonClick() {
      console.log('onAddCartButtonClick');
      try {
        const rrApi = {
          addToBasket: (id) => {
            console.log('adding', id)
          },
        };
        // eslint-disable-next-line no-undef
        rrApi.addToBasket(this.product.id);
      } finally {
        await this.addToCartProduct(this.product);
        this.closeModalHandler()
      }
    },
    toggleToFavoritesItem() {
      if (!this.hasSession) {
        this.setIsOpenedAuthModal(true);
        return
      }

      if (this.isFavorites) {
        this.deleteFavoritesItem(this.product.productId);
      } else {
        this.addFavoritesItem(this.product.productId);
        this.setLastFavoriteAddedItem({
          name: this.product.name,
          brandName: this.product.brandName,
          imgSrc: this.product.image?.url?.small,
        });
      }
    },
    setProductImages() {
      const images = {};
      const media = this.product.media;

      if (Array.isArray(media) && media.length > 0) {
        images.media = media.map((image) => {
          return image.id;
        });
      } else {
        images.media = [];
      }

      this.productImages = images;
    },

  },
}
</script>
