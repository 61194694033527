<template>
  <Nuxt />
</template>

<script>
import login from '~/mixins/login';
import accountChange from '~/mixins/accountChange';

export default {
  name: 'AuthLayout',
  mixins: [login, accountChange],
  middleware: [
    'checkAuthAvailable',
  ],
};
</script>
