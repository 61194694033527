<template>
  <div class="mobile-menu__first">
    <ul class="mobile-menu__list">
      <li class="mobile-menu__item" @click="showCategories">
        <div class="mobile-menu__link">
          Категории
        </div>
        <div class="mobile-menu__link mobile-menu__link--chevron"></div>
      </li>

      <li
        v-for="(menuItem, menuIdx) in menuMain"
        :key="`mm-${menuIdx}`"
        class="mobile-menu__item"
      >
        <div
          v-if="isCurrentRoute(menuItem.url)"
          class="mobile-menu__link"
        >
          {{ menuItem.name }}
        </div>

        <nuxt-link
          v-else
          :to="menuItem.url"
          class="mobile-menu__link"
        >
          {{ menuItem.name }}
        </nuxt-link>
      </li>
    </ul>

    <ul v-if="userText" class="mobile-menu__list mobile-menu__list_bottom">
      <li class="mobile-menu__item">
        <button
          class="mobile-menu__link"
          @click="accountHandler"
        >
          <span v-if="userText" class="mobile-menu__link-account">
            {{ userText }}
          </span>
          <svg-icon v-else name="account-middle" width="16" height="16" />
          Профиль
        </button>
      </li>

      <li class="mobile-menu__item">
        <button
          class="mobile-menu__link"
          @click="openLocationModal"
        >
          <svg-icon name="location-icon" width="24" height="24" />
          {{ getSelectedCity }}
        </button>
      </li>
    </ul>

    <div v-else class="mobile-menu__list__authorize">
      <Button @click="accountHandler">
        Авторизация
      </Button>
    </div>

    <!--    <ul class="mobile-menu__list">-->
    <!--      <li-->
    <!--        v-for="(menuItem, menuIdx) in menuHelp"-->
    <!--        :key="`mh-${menuIdx}`"-->
    <!--        class="mobile-menu__item"-->
    <!--      >-->
    <!--        <a-->
    <!--          v-if="isCurrentRoute(menuItem.url)"-->
    <!--          class="mobile-menu__link"-->
    <!--        >-->
    <!--          {{ menuItem.name }}-->
    <!--        </a>-->

    <!--        <nuxt-link-->
    <!--          v-else-->
    <!--          :to="menuItem.url"-->
    <!--          class="mobile-menu__link"-->
    <!--        >-->
    <!--          {{ menuItem.name }}-->
    <!--        </nuxt-link>-->
    <!--      </li>-->
    <!--    </ul>-->

    <Support />
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex';
import Support from '~/layouts/components/Header/Support'
import {authModalPages} from '~/plugins/enums/auth';
import Button from '@/components/elements/Button';

export default {
  name: 'HeaderMobileMenuFirst',
  components: {Support, Button},
  computed: {
    ...mapState('auth', ['hasSession', 'isOpenedAuthModal', 'currentAuthModalPage']),
    ...mapGetters({
      menuMain: 'getMenuMain',
      menuHelp: 'getMenuHelp',
      getFavoritesCount: 'favorites/getFavoritesCount',
      getSelectedCity: 'geolocation/getSelectedCity',
    }),
    ...mapGetters('auth', ['getAvatarPlaceholder']),
    userText() {
      return this.getAvatarPlaceholder || ''
    },
  },
  methods: {
    ...mapMutations('auth', {
      setIsOpenedAuthModal: 'SET_IS_OPENED_AUTH_MODAL',
      setCurrentAuthModalPage: 'SET_CURRENT_AUTH_MODAL_PAGE',
    }),
    ...mapMutations('geolocation', {
      setVisibleLocationModal: 'SET_VISIBLE_LOCATION_MODAL',
    }),
    isCurrentRoute(url) {
      const isCatalogUrl = url === '/catalog';

      return url === this.$route.path
        || isCatalogUrl && this.$route.path.includes(url) && !this.$route.params.product;
    },
    showCategories(event) {
      this.$emit('showCategories', event)
    },
    accountHandler() {
      if (window.authmodal === 'REGISTRATION') {
        this.setCurrentAuthModalPage(authModalPages.REGISTRATION);
      }
      if (!this.hasSession) {
        this.setIsOpenedAuthModal(true);
      } else {
        this.$router.push({ path: '/profile' })
      }
    },

    openLocationModal() {
      this.setVisibleLocationModal(true);
    },
  },
}
</script>

