<template>
  <div class="container--modal sign-up__footer">
    <div class="sign-up__footer-wrapper">
      <SignInButtons :disabled="disabled" />
    </div>
  </div>
</template>

<script>
import SignInButtons from '@/components/auth/Modal/SignIn/Buttons';

export default {
  name: 'AuthModalFooter',
  components: {
    SignInButtons,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
